import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Status() {
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get('state');
    const message = queryParams.get('message');
    console.log(state);
    const navigate = useNavigate();

    const openClick = (state: string) => {
        if (state === 'success') {
            navigate('/orders', { replace: true });
        } else {
            navigate(-1);
        }
    }


    return (
        <section className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-200">
            <div className="bg-white max-w-[300px] w-[90%] box-border rounded flex flex-col gap-2 items-center">
                <img className="mt-3 h-16 w-16" src={state !== "success" ? '/failed.png' : '/success.png'} alt="" />
                <label className="p-3 font-medium">{message}</label>
                <button className="border-t w-full py-2 font-medium" onClick={() => openClick(state ? state : '')}>{state === 'success' ? 'Go to orders' : 'Close'}</button>
            </div>
        </section>
    );
}

export default Status