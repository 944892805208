// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function getOrders(userId: string) {
  try {
    const response = await axios.get(`${apiUrl}api/orders/${userId}/user`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function getOrderItems(orderId: string) {
  try {
    const response = await axios.get(`${apiUrl}api/orderitems/${orderId}/order`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function cancelOrder(orderId: string, orderData: object) {
  try {
    const response = await axios.put(`${apiUrl}api/orders/${orderId}/cancel`, orderData);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}


async function requestRefund(orderDetails: object) {
  try {
    const response = await axios.post(`${apiUrl}api/refunds`, orderDetails);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}



export { getOrders, getOrderItems, cancelOrder,requestRefund };
