// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function addOrder(orderDetails: object) {
  try {
    const response = await axios.post(`${apiUrl}api/orders`, orderDetails);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function addOrderItems(orderItems: object) {
  try {
    const response = await axios.post(`${apiUrl}api/orderitems`, orderItems);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function addPayment(orderItems: object) {
  try {
    const response = await axios.post(`${apiUrl}api/payments`, orderItems);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function initiatePayment(orderDetails: object) {
  try {
    const response = await axios.post(`${apiUrl}api/pay/initiate`, orderDetails);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function getPayStatus(merchantTransactionId: string) {
  try {
    const response = await axios.get(`${apiUrl}api/pay/status/${merchantTransactionId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function deleteOrder(order_id: string) {
  try {
    const response = await axios.delete(`${apiUrl}api/orders/${order_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

export { addOrder, addOrderItems, addPayment, initiatePayment, getPayStatus, deleteOrder };
