import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import './App.css';
import Home from "./pages/home/Home";
import LoginSignup from "./pages/loginsignup/LoginSignup";
import Orders from "./pages/orders/Orders";
import MarkdownPage from "./pages/markdown/MarkdownPage";
import Order from "./pages/order/Order";
import Addresses from "./pages/addresses/Addresses";
import Status from "./pages/status/Status";

const isCustomerLoggedIn = () => {
  return localStorage.getItem('user');
}

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginSignup />} />
        <Route path="/status" element={<Status />} />
        <Route path="/terms&policies/:markdown" element={<MarkdownPage />} />
        <Route path="/order" element={isCustomerLoggedIn() !== null ? <Order /> : <Navigate to="/login" replace={true} />} />
        <Route path="/orders" element={isCustomerLoggedIn() !== null ? <Orders /> : <Navigate to="/login" replace={true} />} />
        <Route path="/addresses" element={isCustomerLoggedIn() !== null ? <Addresses /> : <Navigate to="/login" replace={true} />} />
      </Routes>
    </Router>
  );
}

export default App;
