import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { changePassword, createUser, login, sendOtp, verifyOtp } from './Util'

type UserForm = { fullname: string, phone: string, password: string, rePassword: string }

type LoadingState = { state: 'loading' }
type FailedState = { state: 'error', errorMessage: string }
type SuccessState = { state: 'success', message: string }
type StatusState = LoadingState | SuccessState | FailedState


type SigninPage = { state: 'sign_in' }
type SignupPage = { state: 'sign_up', userData?: UserForm }
type VerifyOTPPage = { state: 'verify_otp', message: string, userData: UserForm }
type ForgotPage = { state: 'forgot', }
type PageState = SigninPage | SignupPage | ForgotPage | VerifyOTPPage


function LoginSignup() {
    const [pageState, setPageState] = useState<PageState | null>({ state: 'sign_in' });
    return (
        <main className="bg-white fixed top-0 left-0 right-0 bottom-0 flex justify-center overflow-auto">
            <section className="max-w-[500px] w-full flex flex-col">
                <div className="flex-1"></div>
                <div className="box-border p-5">
                    {pageState?.state === 'sign_in' && <Signin onForgotClick={() => setPageState({ state: 'forgot' })} onSignupClick={() => setPageState({ state: 'sign_up' })} />}
                    {pageState?.state === 'sign_up' && <Signup userData={pageState.userData} onSignClick={() => setPageState({ state: 'sign_in' })} onOtpSent={(e) => setPageState(e)} />}
                    {pageState?.state === 'verify_otp' && <VerifyOTP message={pageState.message} formData={pageState.userData} onButtonAction={(e) => setPageState(e)} />}
                    {pageState?.state === 'forgot' && <ForgotPassword onCloseClick={() => setPageState({ state: 'sign_in' })} />}
                </div>
                <div className="flex-1"></div>
            </section>
        </main>
    )
}

const Signin: React.FC<{ onForgotClick: () => void, onSignupClick: () => void }> = ({ onForgotClick, onSignupClick }) => {
    const initialForm = { phone: '', password: '' }
    const [formData, setFormData] = useState<{ phone: string, password: string }>(initialForm);
    const [status, setStatus] = useState<StatusState | null>(null)
    const [alert, setAlert] = useState<string>('');
    const navigate = useNavigate();

    const showAlert = (a: string) => {
        setAlert(a);
        setTimeout(() => {
            setAlert('');
        }, 2000);
    };

    useEffect(() => {
        if (status?.state === 'error') {
            showAlert(status.errorMessage);
        }
    }, [status]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (['phone'].includes(name)) {
            if (!/^\d*$/g.test(value)) {
                showAlert('Input value must be numeric!')
                return;
            }
        }
        setFormData(prevState => ({
            ...prevState!,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setStatus({ state: 'loading' });
        try {
            const loginRes = await login(formData);
            if (loginRes.state === 'user_logged_in') {
                localStorage.setItem('user', loginRes.data);
                window.location.replace('/');
            }
        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
            setStatus({ state: 'error', errorMessage });
        }
    }

    return (
        <div className="relative flex flex-col gap-2">
            <label className="absolute top-0 right-0 left-0 text-red-600">{alert}</label>
            <h2 className='mt-8 text-2xl font-semibold'>SignIn</h2>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="flex items-center pl-3 gap-2 box-border  rounded border-2 border-black">
                    <label className="font-medium">+91</label>
                    <input className="h-10 w-full box-border px-2 font-medium" type="text" placeholder='enter phone' name='phone' value={formData.phone} required maxLength={10} minLength={10} onChange={handleInputChange} disabled={status?.state === 'loading'} />
                </div>
                <input className="h-10 rounded border-2 border-black px-2 font-medium" type="password" name='password' value={formData.password} placeholder='enter password' required onChange={handleInputChange} disabled={status?.state === 'loading'} />
                <button className="bg-black h-10 text-white rounded font-medium flex items-center justify-center" type='submit' disabled={status?.state === 'loading'}>{status?.state === 'loading' ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Signin'}</button>

            </form>
            <button className="text-start text-blue-800 font-medium max-w-[150px]" onClick={onForgotClick} disabled={status?.state === 'loading'}>Forgot password?</button>
            <div className="flex items-center gap-1">
                <label className="font-medium">Don't have account yet?</label>
                <button className=" text-blue-800 font-medium" onClick={onSignupClick} disabled={status?.state === 'loading'}>Create now</button>
            </div>
        </div>
    )
}

const Signup: React.FC<{ userData: UserForm | undefined, onSignClick: () => void, onOtpSent: (state: PageState) => void }> = ({ userData, onSignClick, onOtpSent }) => {
    const initialForm = { fullname: userData?.fullname || '', phone: userData?.phone || '', password: userData?.password || '', rePassword: userData?.rePassword || '' }
    const [formData, setFormData] = useState<UserForm>(initialForm);
    const [status, setStatus] = useState<StatusState | null>(null);
    const [alert, setAlert] = useState<string>('');

    useEffect(() => {
        if (status) {
            switch (status?.state) {
                case 'error':
                    showAlert(status.errorMessage);
                    break;
                case 'success':
                    onOtpSent({ state: 'verify_otp', message: status.message, userData: formData })
                    break;
                default:
                    break;
            }
        }
    }, [status]);


    const showAlert = (a: string) => {
        setAlert(a);
        setTimeout(() => {
            setAlert('');
        }, 2000);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (['phone'].includes(name)) {
            if (!/^\d*$/g.test(value)) {
                showAlert('Input value must be numeric!')
                return;
            }
        }
        setFormData(prevState => ({
            ...prevState!,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (formData.password !== formData.rePassword) {
            showAlert('Password not match!')
            return;
        }
        setStatus({ state: 'loading' });
        try {
            const sendRes = await sendOtp({ phone: formData.phone });
            if (sendRes.state === 'otp_sent') {
                setStatus({ state: 'success', message: sendRes.message });
            }
        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
            setStatus({ state: 'error', errorMessage });
        }
    }
    return (
        <div className="relative flex flex-col gap-2">
            <label className="absolute top-0 right-0 left-0 text-red-600">{alert}</label>
            <h2 className='mt-8 text-2xl font-semibold'>Signup</h2>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="flex flex-col gap-[5px]">
                    <label className="font-medium" htmlFor="fullname">Full Name*</label>
                    <input className="h-10 rounded border-2 border-black px-2 font-medium" type="text" id='fullname' name='fullname' value={formData.fullname} placeholder='enter full name' required onChange={handleInputChange} disabled={status?.state === 'loading'} />
                </div>
                <div className="flex flex-col gap-[5px]">
                    <label className="font-medium" htmlFor="phone">Phone*</label>
                    <div className="flex items-center pl-3 gap-2 box-border  rounded border-2 border-black">
                        <label className="font-medium">+91</label>
                        <input className="h-10 w-full box-border px-2 font-medium" type="text" id='phone' placeholder='enter phone' name='phone' value={formData.phone} required maxLength={10} minLength={10} onChange={handleInputChange} disabled={status?.state === 'loading'} />
                    </div>
                </div>
                <div className="flex flex-col gap-[5px]">
                    <label className="font-medium" htmlFor="password">Password*</label>
                    <input className="h-10 rounded border-2 border-black px-2 font-medium" type="password" id='password' name='password' value={formData.password} placeholder='enter password' required onChange={handleInputChange} disabled={status?.state === 'loading'} />
                </div>
                <div className="flex flex-col gap-[5px]">
                    <label className="font-medium" htmlFor="rePassword">Re-password*</label>
                    <input className="h-10 rounded border-2 border-black px-2 font-medium" type="text" id='rePassword' name='rePassword' value={formData.rePassword} placeholder='re-enter password' required onChange={handleInputChange} disabled={status?.state === 'loading'} />
                </div>
                <button className="bg-black h-10 text-white rounded font-medium flex items-center justify-center" type='submit' disabled={status?.state === 'loading'}>{status?.state === 'loading' ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Signup'}</button>
                <div className="flex items-center gap-1">
                    <label className="font-medium">Already created an account?</label>
                    <button className=" text-blue-800 font-medium" onClick={onSignClick} disabled={status?.state === 'loading'}>Signin now</button>
                </div>
            </form>
        </div>
    )
}

const VerifyOTP: React.FC<{ message: string, formData: UserForm, onButtonAction: (state: PageState) => void }> = ({ message, formData, onButtonAction }) => {
    const [otp, setOtp] = useState<string>('');
    const [status, setStatus] = useState<StatusState | null>(null);
    const [alert, setAlert] = useState<string>('');

    useEffect(() => {
        switch (status?.state) {
            case 'error':
                showAlert(status.errorMessage);
                break
            default:
                break
        }
    }, [status]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!/^\d*$/g.test(value)) {
            return;
        }
        setOtp(value);
    }
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onVerifyClick(otp);
    }

    const onVerifyClick = async (otp: string) => {
        if (otp && formData) {
            setStatus({ state: 'loading' });
            try {
                const verifyRes = await verifyOtp({ otp: Number(otp), phone: formData.phone });
                if (verifyRes.state === 'otp_verified') {
                    const createRes = await createUser(verifyRes.token, formData.phone, { formData });
                    if (createRes.state === 'user_created') {
                        setStatus({ state: 'success', message: createRes.message });
                    }
                }
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                setStatus({ state: 'error', errorMessage });
            }
        }
    }

    const showAlert = (a: string) => {
        setAlert(a);
        setTimeout(() => {
            setAlert('');
        }, 2000);
    };

    const onDialogClosed = useCallback((state: 'success' | 'error') => {
        if (state === 'success') {
            onButtonAction({ state: 'sign_in' });
        } else if (state === 'error') {
            setStatus(null);
        }

    }, [])
    return (
        <div className="relative w-full flex items-center justify-center bg-white box-border px-6" >
            <div className="relative w-full flex flex-col gap-2">
                <label className="absolute top-0 right-0 left-0 text-red-600">{alert}</label>
                <div className="mt-8 flex items-center gap-4">
                    <button className="h-10 w-10 bg-black rounded" onClick={() => onButtonAction({ state: 'sign_up', userData: formData })}><FontAwesomeIcon icon={faArrowLeft} style={{ color: 'white' }} /></button>
                    <h2 className="text-2xl font-semibold">Verify OTP</h2>
                </div>
                <p className="font-semibold">{message}</p>
                <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                    <input className="h-10 max-w-[150px] text-center rounded border-2 border-black px-2 font-medium" type="text" placeholder='enter otp' value={otp} onChange={handleInputChange} required maxLength={6} minLength={6} disabled={status?.state === 'loading'} />
                    <button className="bg-black h-10 text-white rounded font-medium flex items-center justify-center" type='submit' disabled={status?.state === 'loading'}>{status?.state === 'loading' ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Verify'}</button>
                </form>
            </div>
            {
                status?.state === 'success' && <Dialog state='success' message={status.message} onBtnClick={onDialogClosed} />
            }

            {
                status?.state === 'error' && <Dialog state='error' message={status.errorMessage} onBtnClick={onDialogClosed} />
            }
        </div>
    )
}

const ForgotPassword: React.FC<{ onCloseClick: (state: PageState) => void }> = ({ onCloseClick }) => {
    const initialForm = { phone: '', otp: '', password: '', rePassword: '' };
    const [formData, setFormData] = useState<{ phone: string, otp: string, password: string, rePassword: string }>(initialForm);
    const [status, setState] = useState<{ state: 'send_otp', substate: StatusState } | { state: 'submit', substate: StatusState } | null>(null);
    const [alert, setAlert] = useState<string>('');

    useEffect(() => {
        if (status?.state === 'send_otp') {
            if (status.substate.state === 'error') {
                showAlert(status.substate.errorMessage);
            } else if (status.substate.state === 'success') {
                showAlert(status.substate.message);
            }
        }
    }, [status])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (['phone', 'otp'].includes(name)) {
            if (!/^\d*$/g.test(value)) {
                showAlert('Input value must be numeric!')
                return;
            }
        }
        setFormData(prevState => ({
            ...prevState!,
            [name]: value
        }));
    };

    const showAlert = (error: string) => {
        setAlert(error);
        setTimeout(() => {
            setAlert('');
        }, 2000);
    };

    const sendForgotOTP = async () => {
        if (formData.phone.length === 10) {
            setState({ state: 'send_otp', substate: { state: 'loading' } });
            try {
                const sendRes = await sendOtp({ phone: formData.phone });
                if (sendRes.state === 'otp_sent') {
                    setState({ state: 'send_otp', substate: { state: 'success', message: sendRes.message } });
                }
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                console.log(errorMessage);
                setState({ state: 'send_otp', substate: { state: 'error', errorMessage } });

            }
            // setState({ state: 'otp_sending' });
            // sendOTP({ phone: formData.phone }).then(result => {
            //     if (result.error) {
            //         console.log(result.error);
            //         setState(null);
            //     } else if (result.data) {
            //         setState(result.data);
            //     }
            // })
        }

    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (formData.password !== formData.rePassword) {
            showAlert('Password not match!');
            return;
        }
        setState({ state: 'submit', substate: { state: 'loading' } });
        try {
            const verifyRes = await verifyOtp({ otp: Number(formData.otp), phone: formData.phone });
            if (verifyRes.state === 'otp_verified') {
                const changedRes = await changePassword(verifyRes.token, formData.phone, { password: formData.password });
                if (changedRes.state === 'user_updated') {
                    setState({ state: 'submit', substate: { state: 'success', message: changedRes.message } });
                }
            }
        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
            setState({ state: 'submit', substate: { state: 'error', errorMessage } });
        }
    }

    const onDialogClosed = useCallback((state: 'success' | 'error') => {
        if (state === 'success') {
            onCloseClick({ state: 'sign_in' });
        } else if (state === 'error') {
            setState(null);
        }

    }, []);
    return (
        <div className="relative flex flex-col gap-2">
            <label className="absolute top-0 right-0 left-0 text-red-600">{alert}</label>
            <div className="mt-8 flex items-center gap-4">
                <button className="h-10 w-10 bg-black rounded" onClick={() => onCloseClick({ state: 'sign_in' })}><FontAwesomeIcon icon={faArrowLeft} style={{ color: 'white' }} /></button>
                <h2 className="text-2xl font-semibold">Forgot Password</h2>
            </div>

            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <h4 className="text-lg font-medium">Set new password</h4>
                <div className="flex items-center pl-3 box-border  rounded border-2 border-black">
                    <label className="font-medium mr-2">+91</label>
                    <input className="h-10 w-full box-border pl-2 font-medium" type="text" placeholder='enter phone' required maxLength={10} minLength={10} name='phone' value={formData?.phone} onChange={handleInputChange} />
                    <button className="min-w-20 h-10 m-0 font-medium bg-black text-white flex items-center justify-center" disabled={status?.substate?.state === 'loading'} onClick={sendForgotOTP}> {status?.state === 'send_otp' && status?.substate?.state === 'loading' ? <ReactLoading type={'balls'} color={'white'} height={25} width={25} /> : 'send otp'}</button>
                </div>

                <div className="flex flex-col gap-[5px]">
                    <label className="font-medium" htmlFor="otp">Enter OTP</label>
                    <input className="h-10 max-w-[150px] text-center rounded border-2 border-black px-2 font-medium" type="text" id='otp' placeholder='enter otp' required maxLength={6} minLength={6} name='otp' value={formData?.otp} onChange={handleInputChange} disabled={status?.substate?.state === 'loading'} />
                </div>

                <div className="flex flex-col gap-[5px]">
                    <label className="font-medium" htmlFor="rePassword">Enter new password</label>
                    <input className="h-10 rounded border-2 border-black px-2 font-medium" type="password" id='password' placeholder='enter new password' required name='password' value={formData?.password} onChange={handleInputChange} disabled={status?.substate?.state === 'loading'} />
                </div>

                <div className="flex flex-col gap-[5px]">
                    <label className="font-medium" htmlFor="rePassword">Re-enter password</label>
                    <input className="h-10 rounded border-2 border-black px-2 font-medium" type="text" id='rePassword' placeholder='re-enter password' required name='rePassword' value={formData?.rePassword} onChange={handleInputChange} disabled={status?.substate?.state === 'loading'} />
                </div>

                <button className="bg-black h-10 text-white rounded font-medium flex items-center justify-center" type='submit' disabled={status?.substate?.state === 'loading'}> {status?.state === 'submit' && status?.substate?.state === 'loading' ? <ReactLoading type={'balls'} color={'white'} height={25} width={25} /> : 'Continue'}</button>
            </form>
            {
                status?.state === 'submit' && status.substate.state === 'success' && <Dialog state='success' message={status.substate.message} onBtnClick={onDialogClosed} />
            }

            {
                status?.state === 'submit' && status.substate?.state === 'error' && <Dialog state='error' message={status.substate.errorMessage} onBtnClick={onDialogClosed} />
            }
        </div>
    )
}

const Dialog: React.FC<{ state: 'success' | 'error', message: string, onBtnClick: (state: 'success' | 'error') => void }> = ({ state, message, onBtnClick }) => {
    return (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white box-border px-6">
            <div className="w-full bg-gray-200 flex flex-col items-center rounded-md">
                <img className="mt-3 h-16 w-16" src={state === 'success' ? '/success.png' : '/failed.png'} alt="" />
                <label className="px-4 py-2 text-center font-medium">{message}</label>
                <button className="border-t py-2 border-gray-300 w-full font-medium" onClick={() => onBtnClick(state)}>{state === 'success' ? 'Login' : 'Close'}</button>
            </div>
        </div>
    )
}

export default LoginSignup