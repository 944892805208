// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function getMarkDown(endpoint: string) {
  try {
    const response = await axios.get(`${apiUrl}api/system/${endpoint}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}



export { getMarkDown };
