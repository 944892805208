import { faArrowLeft, faPlus, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading';

const isCustomerLoggedIn = () => {
    return localStorage.getItem('user');
}

type DialogData = { state: string, id: string, name: string }

type LoadingState = { state: 'loading' }
type UpdateFailed = { state: 'update_failed', errorMessage: string }
type Updated = { state: 'updated', message: string }
type AddressState = LoadingState | UpdateFailed | Updated

function Addresses() {
    const [showDialog, setShowDialog] = useState<DialogData | null>(null);
    const [user, setUser] = useState<any | null>(null)
    const [state, setState] = useState<AddressState | null>(null);
    const [addresses, setAddresses] = useState<{ id: string, name: string }[]>([]);
    const navigate = useNavigate();
    const isMountedRef = useRef<boolean>(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            const newUser = isCustomerLoggedIn();
            if (newUser) {
                setUser(JSON.parse(newUser));
                fetchAddressess(JSON.parse(newUser).user_id)
            }
            isMountedRef.current = true;
        }
    }, []);

    const fetchAddressess = async (user_id: string) => {
        // const result = await getAddress(user_id);
        // if (result.error) {
        //     console.error('Error:', result.error);
        // } else {
        //     setAddresses(result.data)
        // }
    }

    const onUpdated = (state: AddressState) => {
        setState(state);
        setShowDialog(null);
    }

    const onUpdateCompleted = () => {
        window.location.reload();
    }
    return (
        <div>
            <header className="fixed top-0 left-0 right-0 h-14 bg-black flex items-center justify-center">
                <nav className="relative max-w-[960px] w-[90%]">
                    <button className="text-white absolute top-1/2 transform -translate-y-1/2" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <h1 className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg font-medium">Addresses</h1>
                </nav>
            </header>
            <main className="fixed top-14 left-0 right-0 bottom-0 flex flex-col items-center">
                <section className="relative max-w-[960px] w-[90%] h-full">
                    <div className="absolute top-4 left-0 right-0 h-16 box-border flex items-center justify-between">
                        <h2 className="text-2xl font-medium">My addresses</h2>
                        <button className="flex items-center gap-2" onClick={() => setShowDialog({ state: 'new', id: '', name: '' })}><FontAwesomeIcon icon={faPlus} />Add new</button>
                    </div>
                    <div className="absolute top-20 left-0 right-0 bottom-0 box-border overflow-auto">
                        {addresses.map((address, index) => (
                            <div className="border-b py-3 flex items-center justify-between" key={index}>
                                <p className="font-medium">{address.name}</p>
                                <button className="py-1 px-3 flex items-center  gap-3" onClick={() => setShowDialog({ state: 'update', id: address.id.toString(), name: address.name })}><FontAwesomeIcon icon={faPenToSquare} />Edit</button>
                            </div>
                        ))}
                    </div>
                </section>
            </main>
            {
                showDialog &&
                <FormDialog user_id={user.user_id} dialogData={showDialog} onCloseClick={() => setShowDialog(null)} onUpdated={onUpdated} />
            }

            {
                state && state?.state === 'updated' &&
                <StatusDialog state={state.state} message={state.message} onCloseClick={onUpdateCompleted} />
            }
        </div>
    )
}

const FormDialog: React.FC<{ user_id: string, dialogData: DialogData, onCloseClick: () => void, onUpdated: (state: AddressState) => void }> = ({ user_id, dialogData, onCloseClick, onUpdated }) => {
    const [address, setAddress] = useState<string>(dialogData.name);
    const [loading, setLoading] = useState<boolean>(false);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        // updateAddress({ user_id: user_id, id: dialogData.id, name: address }).then(result => {
        //     setLoading(false);
        //     if (result.error) {
        //         console.log(result.error);
        //         onUpdated({ state: 'update_failed', errorMessage: 'Error occur while updating address' })
        //     } else if (result.data) {
        //         onUpdated(result.data);
        //         console.log(result.data);
        //     }
        // })
    }
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#00000094]">
            <div className="max-w-[500px] w-[90%] bg-white box-border p-4 rounded flex flex-col gap-2">
                <button className="h-10 w-10 self-end border rounded" onClick={onCloseClick}><FontAwesomeIcon icon={faXmark} /></button>
                <h2 className="text-xl font-medium">Add new address</h2>
                <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                    <input className="border-2 border-black rounded h-10 px-3 font-medium" type="text" name='address' value={address} placeholder='enter address name' required onChange={(e) => setAddress(e.target.value)} />
                    <button className="h-10 bg-black text-white rounded font-medium flex items-center justify-center" type='submit' disabled={loading}>{loading ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Add'}</button>
                </form>
            </div>
        </div>
    )
}

const StatusDialog: React.FC<{ state: string, message: string, onCloseClick: () => void }> = ({ state, message, onCloseClick }) => {
    return (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#000000ad]">
            <div className="w-[90%] max-w-[400px] bg-gray-200 flex flex-col items-center rounded-md">
                <img className="mt-3 h-16 w-16" src={state === 'updated' ? '/success.png' : '/failed.png'} alt="" />
                <label className="px-4 py-2 text-center font-medium">{message}</label>
                <button className="border-t py-2 border-gray-300 w-full font-medium" onClick={onCloseClick}>Close</button>
            </div>
        </div>
    )
}

export default Addresses