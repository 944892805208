import { useCallback, useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FaRegClock, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { FaCircleXmark, FaLocationArrow, FaRegMoneyBill1, FaPrint, FaEllipsis } from "react-icons/fa6";
import generatePDF from 'react-to-pdf';
import { useNavigate } from 'react-router-dom';
import { cancelOrder, getOrderItems, getOrders, requestRefund } from './Util';

const isCustomerLoggedIn = () => {
    return localStorage.getItem('user');
}

type Order = {
    fullname: string;
    user_id: string;
    order_id: string;
    phone: string;
    delivery_address: string;
    amount: number;
    payment_id: number;
    payment_mode: string;
    payment_status: string;
    payment_date: string;
    create_time: string;
    status: string;
    refund_status: string;
    items: string;
}


type OrderItem = { img: string, name: string, price: number, quantity: number }

type CancelDialogState = { state: 'cancel_dialog', orderId: string }
type ProgressState = { state: 'progress', message: string }
type FailedState = { state: 'failed', errorMessage: string }
type SuccessState = { state: 'success', updatedData?: { order_id: string, status: string }, message: string }
type OrderState = CancelDialogState | ProgressState | FailedState | SuccessState

function Orders() {
    const [orders, setOrders] = useState<Order[]>([]);
    const user = useRef<any>(isCustomerLoggedIn());
    const [selectedItem, setSelectedItem] = useState<Order | null>(null);
    const [isDetailShow, setDetailShow] = useState<boolean>(false);
    const [alert, setAlert] = useState<string>('');
    const isMountedRef = useRef<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMountedRef.current && user.current) {
            const userId = JSON.parse(user.current).user_id;
            fetchOrders(userId);
            isMountedRef.current = true;
        }
    }, []);


    useEffect(() => {
        if (orders.length > 0) {
            setSelectedItem(orders[0]);
        }
    }, [orders]);


    const fetchOrders = async (userId: string) => {
        try {
            const result = await getOrders(userId);
            setOrders(result);
        } catch (error: any) {
            console.error('Error:', error);
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            setAlert(errorMessage)
        }
    };


    const goBack = () => {
        setDetailShow(!isDetailShow);
        if (!isDetailShow) {
            navigate('/', { replace: true });
        }
    };

    const onOrderCancelled = useCallback((id: string) => {
        setOrders(prevMenus =>
            prevMenus.map(order =>
                order.order_id === id ? { ...order, status: 'cancelled', isCancellable: 0 } : order
            )
        );
    }, []);

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0">
            <header className="fixed top-0 left-0 right-0 h-16 flex items-center justify-center bg-gray-100">
                <nav className="relative w-full">
                    <button className={`absolute left-8 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black `} onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <h1 className="absolute text-xl text-black left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:hidden font-medium">{selectedItem && isDetailShow ? `Order Id: ${selectedItem.order_id}` : 'My orders'}</h1>
                    <h1 className="absolute text-xl text-black left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:block font-medium">My orders</h1>
                </nav>
            </header>
            <main className="fixed top-16 left-0 right-0 bottom-0">
                <section className="relative h-full w-full">
                    <ul className={`absolute bg-white box-border p-4 border-r top-0 bottom-0 lg:w-[450px]  lg:left-0 ${isDetailShow ? 'right-[100%] left-[-100%]' : 'left-0 right-0'} flex flex-col gap-3 overflow-auto transition-all duration-300 ease-in-out`}>
                        {orders.map((order, index) => (
                            <div className={`py-2 px-4 bg-gray-100 rounded flex items-center gap-4 transition-all duration-300 ease-in-out ${selectedItem === order && 'lg:bg-black lg:text-white'}`} key={index} onClick={() => { setSelectedItem(order); setDetailShow(!isDetailShow); }}>
                                <div className="flex-1 h-full flex flex-col justify-center gap-2">
                                    <p className="flex items-center gap-2 font-medium">Order Id: {order.order_id}</p>
                                    <p className="flex items-center gap-2 font-medium"><FaRegClock />{order.create_time.split('/')}</p>
                                    <p className={`flex items-center gap-2 font-medium ${setStatusStyle(order.status)}`}>{getStatusIcon(order.status)}{capitalizeFirstLetter(order.status)}</p>
                                </div>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        ))}
                    </ul>
                    <div className={`absolute bg-white box-border p-6 lg:px-10 lg:py-4 top-0 bottom-0 lg:left-[450px] ${isDetailShow ? 'left-0 right-0' : 'right-[-100%] left-[100%]'} lg:right-0 overflow-auto transition-all duration-300 ease-in-out`}>
                        {
                            selectedItem &&
                            <Detail user={user} order={selectedItem} onOrderCancelled={onOrderCancelled} />
                        }
                    </div>
                </section>
                {
                    alert && <label className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black font-medium">{alert}</label>
                }
            </main>
        </div>
    )
};

const Detail: React.FC<{ user: any, order: Order, onOrderCancelled: (id: string) => void }> = ({ user, order, onOrderCancelled }) => {
    const [state, setState] = useState<OrderState | null>(null);
    const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
    const isMountedRef = useRef<boolean>(false);

    useEffect(() => {
        // if (!isMountedRef.current && order.order_id) {

        //     isMountedRef.current = true;
        // }
        fetchOrderItems(order.order_id)
    }, [order]);


    const fetchOrderItems = async (orderId: string) => {
        setOrderItems([]);
        try {
            const result = await getOrderItems(orderId);
            setOrderItems(result);
        } catch (error: any) {
            console.error('Error:', error);
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
        }
    };


    const dialogBtnClick = useCallback((state: OrderState) => {
        if (state.state === 'success') {
            window.location.reload();
        }
        setState(null);
    }, [])

    const cancelClick = (order: Order) => {
        setState({ state: 'cancel_dialog', orderId: order.order_id });
    };

    const confirmedCancel = async () => {
        if (state?.state === 'cancel_dialog') {
            if (user.current) {
                const userId = JSON.parse(user.current).user_id
                if (userId) {
                    setState({ state: 'progress', message: 'Cancelling order in progress....' });
                    try {
                        const orderCancel = await cancelOrder(order.order_id, { status: 'cancelled', user_id: userId });
                        if (orderCancel && orderCancel.refundable) {
                            const refundRes = await requestRefund({ order_id: order.order_id, user_id: order.user_id });
                            console.log(refundRes);
                        }
                        setState({ state: 'success', message: 'Order cancelled successfully' });
                    } catch (error: any) {
                        let errorMessage = 'Internal Server Error';
                        if (error.code === 'ERR_NETWORK') {
                            errorMessage = error.message;
                        } else if (error.code === 'ERR_BAD_REQUEST') {
                            errorMessage = error.response?.data?.error || 'Bad Request Error';
                        }
                        setState({ state: 'failed', errorMessage });
                    }
                }
            }
        }
    };

    const addRefund = async (order: Order) => {
        if (order.order_id && order.user_id) {
            setState({ state: 'progress', message: 'Requesting for refund, please wait....' })
            try {
                const refundRes = await requestRefund({ order_id: order.order_id, user_id: order.user_id });
                setState({ state: 'success', message: 'Refund requested' });
            } catch (error: any) {
                let errorMessage = 'Internal Server Error';
                if (error.code === 'ERR_NETWORK') {
                    errorMessage = error.message;
                } else if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage = error.response?.data?.error || 'Bad Request Error';
                }
                setState({ state: 'failed', errorMessage });
            }
        }
    }

    return (
        <div className="w-full flex flex-col gap-10 ">
            <div className="flex justify-between">
                <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-3 font-medium" ><label>Order Id:</label><label>{order.order_id}</label></div>
                    <div className="flex items-center gap-3 font-medium"><FaRegClock /><label>{order.create_time.split("/")}</label></div>
                    <div className="flex items-center gap-3 font-medium"><FaPhoneAlt /><label>{order.phone}</label></div>
                    <div className="flex items-center gap-3 font-medium"><FaLocationArrow /><label>{order.delivery_address}</label></div>
                    <div className="flex flex-wrap items-center gap-3 font-medium"><FaRegMoneyBill1 />
                        {order.payment_mode && order.payment_status ? <><p>{capitalizeFirstLetter(order.payment_mode)}</p><p className={`text-sm font-medium bg-slate-200 px-3 rounded-full ${setPaymentStatusStyle(order.payment_status)}`}>{order.payment_status}</p></> : <p>NOT AVAILABLE</p>}</div>
                    <div className="flex flex-wrap items-center gap-2">
                        <label className={`flex items-center gap-3 font-medium ${setStatusStyle(order.status)}`}>{getStatusIcon(order.status)}{capitalizeFirstLetter(order.status)}</label>
                        {
                            order.refund_status &&
                            <label className={`text-sm bg-gray-200 px-3 font-medium rounded-full ${setPaymentStatusStyle(order.refund_status)}`}>{order.refund_status}</label>
                        }
                    </div>
                    <div className="flex gap-3 mt-3">
                        <button className="flex px-4 py-1 rounded items-center gap-3 bg-red-500 text-white font-medium disabled:opacity-30" disabled={order.refund_status !== null || order.status === 'cancelled'} onClick={() => cancelClick(order)}><FaCircleXmark />Cancel</button>
                        {
                            order.status === 'cancelled' && !order.refund_status && ['PAYMENT_SUCCESS'].includes(order.payment_status) ?
                                <button className="flex px-4 py-1 rounded items-center gap-3 bg-gray-100 border border-black text-black font-medium disabled:opacity-30" onClick={() => addRefund(order)}><FaCircleXmark />Request for refunds</button> : null
                        }
                        <Receipt order={order} orderItems={orderItems} />
                    </div>
                </div>
                {orderItems.length > 0 &&
                    <p className="font-medium text-xl">₹{orderItems.reduce((i, c) => i + (c.price * c.quantity), 0)}</p>
                }
            </div>
            <div className="flex flex-col">
                <h4 className="font-medium">Items:</h4>
                <div className="flex flex-col">
                    {orderItems.map((item, index) => (
                        <div className="py-3 border-b flex items-center gap-4" key={index} >
                            <img className="min-w-[70px] max-w-[70px] h-[70px] object-cover rounded" src={item.img} alt="" />
                            <div className="flex flex-col gap-1">
                                <p className="font-medium">{item.name}</p>
                                <div className="flex flex-wrap gap-1">
                                    <p className="bg-gray-100 px-3 rounded text-sm">Price: ₹{item.price}</p>
                                    <p className=" bg-gray-100 px-3 rounded text-sm">Quantity: {item.quantity}</p>
                                    <p className="bg-gray-100 px-3 rounded text-sm">Total Amount: ₹{item.price * item.quantity}</p>
                                </div>
                            </div>
                        </div >
                    ))}
                </div >
            </div >
            <div>
                {state?.state === 'cancel_dialog' &&
                    <ConfirmationDialog onCancelled={() => setState(null)} onConfirmed={confirmedCancel} />
                }

                {state?.state === 'progress' &&
                    <ProgressDialog message={state.message} />
                }
                {state?.state === 'success' &&
                    <StatesDialog state={state} onButtonClick={dialogBtnClick} />
                }
                {state?.state === 'failed' &&
                    <StatesDialog state={state} onButtonClick={dialogBtnClick} />
                }
            </div>
        </div >
    )
}

const ConfirmationDialog: React.FC<{ onCancelled: () => void, onConfirmed: () => void }> = ({ onCancelled, onConfirmed }) => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#000000ad]">
            <div className="max-w-[300px] w-[90%]  bg-white rounded flex flex-col shadow-lg shadow-gray-700">
                <label className="m-4 text-center font-medium">Are you sure want to cancel this order?</label>
                <div className="border-t flex items-center">
                    <button className="flex-1 h-10 border-r py-1 font-medium" onClick={onCancelled}>No</button>
                    <button className="flex-1 h-10 flex items-center justify-center font-medium" onClick={onConfirmed} >Confirm</button>
                </div>
            </div>
        </div>
    )
};

const ProgressDialog: React.FC<{ message: string }> = ({ message }) => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#000000ad]">
            <div className="max-w-[300px] w-[90%]  bg-white rounded flex  flex-col items-center justify-center gap-2 shadow-lg shadow-gray-700 box-border p-4">
                <label className="text-center font-medium">{message}</label>
                <ReactLoading type={'balls'} color={'black'} height={30} width={30} />
            </div>
        </div>
    )
};

const StatesDialog: React.FC<{ state: OrderState, onButtonClick: (state: OrderState) => void }> = ({ state, onButtonClick }) => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#000000ad]">
            <div className="max-w-[300px] w-[90%]  bg-white rounded flex items-center flex-col shadow-lg shadow-gray-700 gap-3">
                <img className="mt-3 h-16 w-16" src={state.state === 'success' ? '/success.png' : '/failed.png'} alt="" />
                <label className="mx-4 text-sm">{getStateMsg(state)}</label>
                <button className="w-full border-t h-10 flex items-center justify-center font-medium text-sm" onClick={() => onButtonClick(state)}>Close</button>
            </div>
        </div>
    )
};

const Receipt: React.FC<{ order: Order, orderItems: OrderItem[] }> = ({ order, orderItems }) => {
    const targetRef = useRef<HTMLDivElement | null>(null);
    const options = {
        orientation: 'portrait',
        filename: 'brunch_receipt.pdf',
        unit: 'in',
        format: [4, 2]
    };
    return (
        <div>
            <div className="h-0 w-0 overflow-hidden">
                <div className="box-border p-8 flex flex-col items-center gap-10 w-[790px] min-h-[1100px] bg-white" ref={targetRef}>
                    <h3 className="text-3xl font-semibold">INVOICE</h3>
                    <p className="text-2xl font-semibold">Brunch - The Royal Veg</p>
                    <div className="flex gap-2"><p className="text-lg font-semibold">Order Id:</p> <p className="text-lg font-semibold">{order.order_id}</p></div>
                    <div className="w-full grid grid-cols-2 gap-2 font-medium">
                        <div className="flex flex-col gap-2">
                            <p>{order.fullname}</p>
                            <p>{order.delivery_address}</p>
                            <p>{order.phone}</p>
                        </div>
                        <div className="flex flex-col gap-2 items-end">
                            <p className="font-medium">{order.create_time}</p>
                            <p className="font-medium">{'NOT AVAILABLE' || order.payment_mode.toUpperCase()}</p>
                            <p className={`font-medium ${setStatusStyle(order.status)}`}>{capitalizeFirstLetter(order.status)}</p>
                        </div>

                    </div>
                    <table className="w-full font-medium border text-black">
                        <thead className="bg-black">
                            <tr className="text-white">
                                <th className="border box-border p-2 text-start">Name</th>
                                <th className="border box-border p-2 text-start">Price</th>
                                <th className="border box-border p-2 text-start w-2">Qnty</th>
                                <th className="border box-border p-2 text-start">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderItems.map((item, index) => (
                                <tr key={index}>
                                    <td className="border box-border p-2 text-start">{item.name}</td>
                                    <td className="border box-border p-2 text-start">₹{item.price}</td>
                                    <td className="border box-border p-2 text-start w-2">{item.quantity}</td>
                                    <td className="border box-border p-2 text-start">₹{item.price * item.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                        <thead>
                            <tr>
                                <th className="border box-border p-2 text-start"></th>
                                <th className="border box-border p-2 text-start" colSpan={2}>Total Amount</th>
                                <th className="border box-border p-2 text-start">₹{orderItems.reduce((i, o) => i + (o.price * o.quantity), 0)}</th>
                            </tr>
                        </thead>
                    </table>
                    <p className="text-xl font-semibold">Thank You</p>
                </div>
            </div>
            <button className="flex px-4 py-1 rounded items-center gap-3 bg-black text-white font-medium" disabled={orderItems.length === 0} onClick={() => generatePDF(targetRef, options)}><FaPrint />Receipt</button>
        </div>
    )
};

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const getStateMsg = (state: OrderState) => {
    if (state.state === 'failed') {
        return state.errorMessage;
    } else if (state.state === 'success') {
        return state.message;
    }
};

function setStatusStyle(status: string) {
    switch (status) {
        case 'delivered':
            return 'text-green-500';
        case 'cancelled':
            return 'text-red-500';
        case 'confirmed':
            return 'text-blue-500';
        case 'pending':
            return 'text-slate-500';
        default:
            return 'text-black';
    }
};

function setPaymentStatusStyle(status: string) {
    switch (status) {
        case 'PAYMENT_SUCCESS':
            return 'text-green-700';
        case 'PAYMENT_PENDING':
            return 'text-yellow-600';
        default:
            return 'text-red-700';
    }
};


const getStatusIcon = (status: string) => {
    switch (status) {
        case 'delivered':
        case 'confirmed':
            return <FaCheckCircle />
        case 'pending':
            return <FaEllipsis />
        case 'cancelled':
            return <FaCircleXmark />
        default:
            return <label>Status</label>

    }
};

export default Orders;
