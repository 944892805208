// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function fetchMenus() {
  try {
    const response = await axios.get(`${apiUrl}api/menus`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function createGuest() {
  try {
    const response = await axios.get(`${apiUrl}api/users/createguest`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

export { fetchMenus,createGuest };
