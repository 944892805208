// fetchItems.ts
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

async function login(data: object) {
  try {
    const response = await axios.post(`${apiUrl}api/users/login`, data);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function sendOtp(data: object) {
  try {
    const response = await axios.post(`${apiUrl}api/otp/send`, data);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function verifyOtp(data: object) {
  try {
    const response = await axios.post(`${apiUrl}api/otp/verify`, data);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function createUser(token: string, phone: string, data: object) {
  try {
    const response = await axios.post(`${apiUrl}api/users/${phone}/create`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}

async function changePassword(token: string, phone: string, data: object) {
  try {
    const response = await axios.put(`${apiUrl}api/users/${phone}/changepassword`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}




export { login, sendOtp, verifyOtp, createUser, changePassword };
