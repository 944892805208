import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { addOrder, addOrderItems, addPayment, deleteOrder, getPayStatus, initiatePayment } from './Util';

const isUserLoggedIn = () => {
    return localStorage.getItem('user');
}

type Orderitem = { id: number, name: string, price: number, quantity: number }
type OrderFormData = { user_id: string, fullname: string, phone: string, delivery_address: string, payment_mode: string };

type LoadingState = { state: 'loading' }
type FormState = { state: 'form' }
type ConfirmationState = { state: 'confirmation' }

type OrderSuccessState = { state: 'success', message: string }
type OrderFailedState = { state: 'error', errorMessage: string }
type OrderState = FormState | ConfirmationState | LoadingState | OrderFailedState | OrderSuccessState | OrderFailedState


function Order() {
    const queryParams = new URLSearchParams(window.location.search);
    const formDataString = queryParams.get('data');
    const [state, setState] = useState<OrderState>({ state: 'form' });
    const [orderItems, setOrderItems] = useState<Orderitem[]>([]);
    const [formData, setFormData] = useState<OrderFormData | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (formDataString) {
            const decodedData = JSON.parse(decodeURIComponent(formDataString))
            setOrderItems(decodedData);
        }

        const isLoggedIn = isUserLoggedIn();
        if (isLoggedIn) {
            setFormData({ ...formData, ...JSON.parse(isLoggedIn) });
        }

    }, []);

    const onSubmitForm = useCallback((formData: OrderFormData) => {
        setFormData(formData);
        setState({ state: 'confirmation' });
    }, [])

    const onOrderConfirmed = useCallback((confirmState: OrderState) => {
        setState(confirmState);
    }, [])


    const onConfirmBackClicked = useCallback(() => {
        setState({ state: 'form' });
    }, [])

    const onStatusClosed = useCallback(async (status: OrderState) => {
        if (status.state === 'success') {
            navigate('/orders', { replace: true });
        } else {
            setState({ state: 'form' });
        }
    }, []);

    return (
        <main className="fixed bg-white sm:bg-gray-300 top-0 left-0 right-0 bottom-0 flex justify-center overflow-auto">
            <section className="max-w-[600px] w-full sm:w-[95%] h-full  box-border flex flex-col items-center">
                <div className="flex-1"></div>
                {
                    state.state === 'form' && FormPage(formData, onSubmitForm)
                }

                {
                    state.state === 'confirmation' && ConfirmationPage(onConfirmBackClicked, orderItems, formData, onOrderConfirmed)
                }

                {
                    state.state === 'error' && <OrderStatusDialog status={state} message={state.errorMessage} onCloseClick={onStatusClosed} />
                }

                {
                    state.state === 'success' && <OrderStatusDialog status={state} message={state.message} onCloseClick={onStatusClosed} />
                }

                <div className="flex-1"></div>
            </section>
        </main>
    )
}

const FormPage = (formData: OrderFormData | null, onSubmitForm: (formData: OrderFormData) => void) => {
    if (formData?.user_id) {
        return <OrderForm onSubmit={onSubmitForm} orderFormData={formData} />
    }
    return <label>No user details found!!</label>
}

const ConfirmationPage = (onConfirmBack: () => void, orderItems: Orderitem[], formData: OrderFormData | null, onOrdered: (state: OrderState) => void) => {
    if (!formData?.user_id) {
        return <label>No user details found!</label>
    } else if (orderItems.length == 0) {
        return <label>No items found!</label>
    } else if (!formData.delivery_address) {
        return <label>Please fill up all the necessary feilds!</label>
    } else {
        return <Confirmation onBackClick={onConfirmBack} orderItems={orderItems} formData={formData} orderStatus={onOrdered} />
    }
}

const OrderForm: React.FC<{ onSubmit: (formData: OrderFormData) => void, orderFormData: OrderFormData }> = ({ onSubmit, orderFormData }) => {
    const initialFormData: OrderFormData = useMemo(() => ({
        user_id: orderFormData.user_id,
        fullname: orderFormData.fullname,
        phone: orderFormData.phone || '',
        delivery_address: orderFormData.delivery_address || '',
        payment_mode: orderFormData.payment_mode || 'online'
    }), [orderFormData]);

    const [formData, setFormData] = useState<OrderFormData>(initialFormData);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked ? value : 'online' : value;

        if (name === 'phone' && !/^\d*$/g.test(newValue)) {
            return;
        }
        setFormData({ ...formData, [name]: newValue });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    }

    return (
        <div className="bg-white w-full box-border p-6 rounded flex flex-col gap-3">
            <h2 className="text-2xl font-medium">Order</h2>
            <form onSubmit={handleSubmit} className="flex flex-col">
                <div className="flex gap-2 py-3 border-b"><label className="flex-1 font-medium">Name:</label><label className="flex-1 font-medium">{formData.fullname}</label></div>
                <div className="flex flex-col gap-1 py-3 border-b"><label className="font-medium" htmlFor='phone'>Contact number:</label><input className="flex-1 min-h-10 box-border rounded border-2 border-black px-2 font-medium" type="text" id='phone' name='phone' value={formData.phone} maxLength={10} minLength={10} placeholder='enter phone number' required={!formData.phone} onChange={handleInputChange} /></div>
                <div className="flex flex-col gap-1 py-3 border-b"><label className="font-medium" htmlFor='delivery_address'>Address:</label><textarea className="box-border rounded border-2 border-black px-2 font-medium resize-none" rows={3} id='delivery_address' name='delivery_address' value={formData.delivery_address} placeholder='enter delivery address' onChange={handleInputChange} required /></div>
                <div className="flex gap-4 items-center flex-wrap py-3 border-b">
                    <label className="font-medium">Cash on delivery:</label>
                    <input className="h-5 w-5"
                        type="checkbox"
                        name="payment_mode"
                        value="cash"
                        checked={formData.payment_mode === 'cash'}
                        onChange={handleInputChange}
                    />
                </div>
                <button className="bg-black mt-5 h-10 text-white rounded font-medium flex items-center justify-center" type='submit' >Next</button>
            </form>
        </div>
    )
}

const Confirmation: React.FC<{ onBackClick: () => void, orderItems: Orderitem[], formData: OrderFormData, orderStatus: (orderState: OrderState) => void }> = ({ onBackClick, orderItems, formData, orderStatus }) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const orderId = useRef<string | null>(null);
    const finalSubmit = async () => {
        setLoading(true);
        const itemsToInsert = orderItems.map(i => ({ id: i.id, quantity: i.quantity }));
        try {
            const result = await addOrder(formData);
            if (result && result.order_id) {
                orderId.current = result.order_id;
                const result2 = await addOrderItems({ order_id: result.order_id, items: itemsToInsert });
                if (result2) {
                    const result3 = await addPayment({ order_id: result.order_id, user_id: formData.user_id, payment_mode: formData.payment_mode });
                    if (result3 && result3.payment_id) {
                        if (formData.payment_mode === 'cash') {
                            orderStatus({ state: 'success', message: 'Order Successfull' });
                        } else {
                            const initiatePay = await initiatePayment({ order_id: result.order_id, phone: formData.phone, payment_id: result3.payment_id });
                            if (initiatePay && initiatePay.redirectUrl) {
                                window.location.href = initiatePay.redirectUrl;
                            }
                        }
                    }
                }
            }
        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
            if (orderId.current) removeOrder(orderId.current);
            orderStatus({ state: 'error', errorMessage });
        } finally {
            setLoading(false);
        }
    }

    const removeOrder = async (order_id: string) => {
        try {
            const deleteRes = await deleteOrder(order_id);
            console.log(deleteRes);

        } catch (error: any) {
            let errorMessage = 'Internal Server Error';
            if (error.code === 'ERR_NETWORK') {
                errorMessage = error.message;
            } else if (error.code === 'ERR_BAD_REQUEST') {
                errorMessage = error.response?.data?.error || 'Bad Request Error';
            }
            console.log(errorMessage);
        }
    }
    return (
        <div className="bg-white w-full box-border rounded flex flex-col">
            <div className="flex items-center gap-3 p-4 border-b">
                <button className="h-10 w-10 bg-black text-white rounded" onClick={onBackClick}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <h2 className="text-2xl font-medium">Order Confirmation</h2>
            </div>

            <div className="flex flex-col p-4 ">
                <div className="flex gap-2 py-2 border-b"><label className="flex-1 font-medium">Name:</label><label className="flex-1 font-medium">{formData.fullname}</label></div>
                <div className="flex gap-2 py-2 border-b"><label className="flex-1 font-medium">Contact number:</label><label className="flex-1 font-medium">{formData.phone}</label></div>
                <div className="flex gap-2 py-2 border-b"><label className="flex-1 font-medium">Address:</label><label className="flex-1 font-medium">{formData.delivery_address}</label></div>
                <div className="flex gap-2 py-2 border-b"><label className="flex-1 font-medium">Payment Mode:</label><label className="flex-1 font-medium">{formData.payment_mode.toUpperCase()}</label></div>
            </div>
            <div className="flex flex-col gap-1 p-4 ">
                <label className="text-lg font-medium">Items:</label>
                <table className="text-sm  w-full font-medium border">
                    <thead>
                        <tr>
                            <th className="border box-border p-2 text-start">Name</th>
                            <th className="border box-border p-2 text-start">Price</th>
                            <th className="border box-border p-2 text-start w-2">Qnty</th>
                            <th className="border box-border p-2 text-start">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderItems.map((item, index) => (
                            <tr key={item.id}>
                                <td className="border box-border p-2 text-start">{item.name}</td>
                                <td className="border box-border p-2 text-start">₹{item.price}</td>
                                <td className="border box-border p-2 text-start w-2">{item.quantity}</td>
                                <td className="border box-border p-2 text-start">₹{item.price * item.quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                    <thead>
                        <tr>
                            <th className="border box-border p-2 text-start"></th>
                            <th className="border box-border p-2 text-start" colSpan={2}>Total Amount</th>
                            <th className="border box-border p-2 text-start">₹{orderItems.reduce((i, o) => i + (o.price * o.quantity), 0)}</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="p-4 flex flex-col gap-4">
                <label className="text-sm font-medium text-gray-700">
                    <b>Note:</b> Please verify the provided information for accuracy. If correct, click "confirm" to proceed with the order.
                </label>
                <div className="flex justify-between">
                    <button className="text-black bg-white px-3 py-1 rounded font-medium flex items-center gap-3 border" onClick={onBackClick} disabled={isLoading}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                    <button className="text-blue-500 w-[120px] bg-white px-3 py-1 rounded font-medium flex items-center justify-center gap-3 border border-blue-500" disabled={isLoading} onClick={finalSubmit}>{isLoading ? <ReactLoading type={'balls'} color={'#3b82f6'} height={25} width={25} /> : <>Confirm  <FontAwesomeIcon icon={faArrowRight} /></>}</button>
                </div>

            </div>
        </div>
    )
}

const OrderStatusDialog: React.FC<{ status: OrderState, message: string, onCloseClick: (status: OrderState) => void }> = ({ status, message, onCloseClick }) => {
    return (
        <div className="bg-white max-w-[300px] w-[90%] box-border rounded flex flex-col gap-2 items-center">
            <img className="mt-3 h-16 w-16" src={status.state === 'success' ? '/success.png' : '/failed.png'} alt="" />
            <label className="p-3">{message}</label>
            <button className="border-t w-full py-2" onClick={() => onCloseClick(status)}>{status.state === 'success' ? 'Go to orders' : 'Close'}</button>
        </div>
    )
}

export default Order