import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styles from './Markdown.module.css';
import { getMarkDown } from './Util';

type LoadingState = { state: 'loading' };
type ErrorState = { state: 'error', errorMessage: string };
type SuccessState = { state: 'success', data: string }
type PageState = LoadingState | ErrorState | SuccessState

function MarkdownPage() {
  const { markdown } = useParams();
  const [state, setState] = useState<PageState | null>(null);
  const [isValidMarkdown, setIsValidMarkdown] = useState(true);
  const navigate = useNavigate();
  let isThrottled = false;

  useEffect(() => {
    const validateMarkdown = () => {
      if (markdown && isParamValid(markdown)) {
        setIsValidMarkdown(true);
      } else {
        setIsValidMarkdown(false);
      }
    };

    validateMarkdown();
  }, [markdown]);

  useEffect(() => {
    throttleFetchData();
    return () => {

    };
  }, []);

  const throttleFetchData = () => {
    if (!isThrottled) {
      fetchMenus();
      isThrottled = true;
      setTimeout(() => {
        isThrottled = false;
      }, 300);
    }
  };
  const fetchMenus = async () => {
    if (markdown && isParamValid(markdown)) {
      setState({ state: 'loading' });
      try {
        const markdownRes = await getMarkDown(markdown.toString());
        setState({ state: 'success', data: markdownRes })
      } catch (error: any) {
        let errorMessage = 'Internal Server Error';
        if (error.code === 'ERR_NETWORK') {
          errorMessage = error.message;
        } else if (error.code === 'ERR_BAD_REQUEST') {
          errorMessage = error.response?.data?.error || 'Bad Request Error';
        }
        console.log(errorMessage);
        setState({ state: 'error', errorMessage });
      }
    }

  }

  if (!isValidMarkdown) {
    return <Navigate to="/error" replace={true} />;
  }
  return (
    <div>
      <header className="fixed top-0 left-0 right-0 h-14 bg-black flex items-center justify-center">
        <nav className="relative max-w-[1280px] w-[90%]">
          <button className="text-white absolute top-1/2 transform -translate-y-1/2" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
          {markdown && isParamValid(markdown) && <h1 className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg font-medium">{getHeader(markdown)}</h1>}
        </nav>
      </header>
      <main className="fixed top-14 left-0 right-0 bottom-0 flex justify-center overflow-auto">
        {state?.state === 'loading' && <p className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black font-medium">Loading....</p>}
        {state?.state === 'success' && <section className="max-w-[1280px] w-[95%] py-5"><ReactMarkdown className={styles.markdown} rehypePlugins={[rehypeRaw]}>{state.data}</ReactMarkdown></section>}
        {state?.state === 'error' && <p className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black font-medium">{state.errorMessage}</p>}
      </main>
    </div>
  )
}

const getHeader = (state: string) => {
  switch (state) {
    case 'termsofuse':
      return 'Terms of use';
    case 'privacypolicy':
      return 'Privacy Policy';
    case 'refundpolicy':
      return 'Refund Policy';
    case 'logisticspolicy':
      return 'Logistics Policy';
    default:
      return '';
  }
}

const isParamValid = (param: string) => {
  if (['termsofuse', 'privacypolicy', 'refundpolicy', 'logisticspolicy'].includes(param)) {
    return true;
  }
  return false;
}

export default MarkdownPage