import React, { useCallback, useEffect, useRef, useState } from 'react'
// import styles from './Home.module.css'
// import { createGuestUser, getMenus } from '../../Service';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faBarsStaggered, faRightToBracket, faPenToSquare, faFile, faShieldHalved, faTruckFast, faPhone, faBox, faPlus, faMinus, faTrash, faClose, faLocationDot, faXmark, faL } from '@fortawesome/free-solid-svg-icons'
import ReactLoading from 'react-loading';
import axios from 'axios';
import { createGuest, fetchMenus } from './Util';


const isUserLoggedIn = () => {
  return localStorage.getItem('user');
}


type Menu = { id: number, name: string, price: number, img: string, quantity: number, isAvailable: string; }
type ShowCart = { state: 'cart' }
type ShowMore = { state: 'more' }
type ShowAuth = { state: 'auth' }
type GuestCreated = { state: 'guest_created', user: string, message: string }
type GuestCreateFailed = { state: 'failed_to_create_guest', errorMessage: string }
type PageState = ShowCart | ShowMore | ShowAuth | GuestCreated | GuestCreateFailed
function Home() {
  const [state, setState] = useState<PageState | null>(null);
  const [cartItems, setCartItems] = useState<Menu[]>([]);
  // const [isCartOpened, setCartOpened] = useState<boolean>(false);
  // const [isMoreOpened, setAccountOpened] = useState<boolean>(false);
  const [user, setUser] = useState<{ fullname: string, phone: string } | null>(null);

  useEffect(() => {
    const newUser = isUserLoggedIn();
    if (newUser) {
      setUser(JSON.parse(newUser));
    }
  }, [])

  useEffect(() => {
    const newUser = isUserLoggedIn();
    if (newUser) {
      setUser(JSON.parse(newUser));
    }
  }, [state?.state === 'guest_created']);

  const onCartAdded = (menu: Menu) => {
    if (!user) return setState({ state: 'auth' });
    if (menu.isAvailable) {
      const existingIndex = cartItems.findIndex(item => item.id === menu.id);
      if (existingIndex !== -1) {
        const updatedMenu = [...cartItems];
        updatedMenu[existingIndex] = { ...updatedMenu[existingIndex], quantity: updatedMenu[existingIndex].quantity + menu.quantity };
        setCartItems(updatedMenu);
      } else {
        setCartItems([...cartItems, menu]);
      }
      doVibrate();
    }
  }

  const removeCart = (index: number) => {
    const updatedItems = [...cartItems];
    updatedItems.splice(index, 1);
    setCartItems(updatedItems);
    doVibrate();
  }

  const updateCartQty = useCallback((type: string, index: number) => {
    setCartItems(prevItems => {
      const updatedItems = [...prevItems];
      const newQty = type === 'minus' ? updatedItems[index].quantity - 1 : updatedItems[index].quantity + 1;
      updatedItems[index] = { ...updatedItems[index], quantity: newQty < 0 ? 0 : newQty };
      return updatedItems;
    });
  }, []);

  const onLoginOption = useCallback(() => {
    setState({ state: 'auth' });
  }, [])


  const onCartClick = () => {
    if (state && state.state == 'cart') {
      setState(null);
    } else {
      setState({ state: 'cart' });
    }
  }

  const onMoreClick = () => {
    if (state && state.state == 'more') {
      setState(null);
    } else {
      setState({ state: 'more' });
    }
  }

  const onGuestCreateCallback = useCallback((st: PageState | null) => {
    if (st?.state === 'guest_created') {
      localStorage.setItem('user', st.user);
    }
    setState(st);
  }, [])

  return (
    <>
      <header className="fixed top-0 left-0 right-0 h-16 bg-gray-100">
        <Navbar onHeaderClick={() => setState(null)} onCartClick={onCartClick} state={state} onMoreClick={onMoreClick} cartLength={cartItems.length} />
      </header>
      <main className="fixed top-16 bottom-0 left-0 right-0 lg:bottom-10">
        <section className="relative h-full w-full">
          <h2 className="hidden">Menus</h2>
          <div className={`absolute box-border p-4 bg-white top-0 bottom-0 ${state && state.state === 'cart' ? 'left-[-100%] right-[100%]' : 'left-0 right-0'} lg:left-0 lg:right-[450px] overflow-auto transition-all duration-300 ease-in-out`}>
            <Menus onAddClick={onCartAdded} />
          </div>
          <div className={`absolute box-border px-4 py-2 bg-white top-0 bottom-0  ${state && state.state === 'cart' ? 'left-0 right-0' : 'right-[-100%] left-[100%]'} lg:w-[450px] lg:right-0 lg:left-auto transition-all duration-300 ease-in-out`}>
            <Cart user={user} carts={cartItems} onRemove={removeCart} onQntyUpdated={updateCartQty} onLoginOption={onLoginOption} />
          </div>
          <div className={`absolute box-border px-6 py-4 border-t bg-gray-100 top-0 bottom-0 ${state && state.state === 'more' ? 'lg:w-[450px] left-0 right-0' : 'right-[-100%] left-[100%]'} lg:left-auto overflow-auto transition-all duration-300 ease-in-out`}>
            <MoreDialog user={user} onLogout={() => setUser(null)} onLoginClick={() => setState({ state: 'auth' })} />
          </div>
        </section>
      </main>
      <footer className='fixed left-0 right-0 bottom-0 h-10 hidden lg:block bg-gray-100'>
        <Footer />
      </footer>
      {
        state && state.state === 'auth' &&
        <LoginDialog onCloseClick={() => setState(null)} onGuestCreateCallback={onGuestCreateCallback} />
      }

      {
        state && state.state === 'guest_created' &&
        <GuestCreateALert message={state.message} onGuestAlertDialogClose={() => window.location.reload()} />
      }

      {
        state && state.state === 'failed_to_create_guest' &&
        <GuestCreateALert message={state.errorMessage} onGuestAlertDialogClose={() => setState(null)} />
      }
    </>
  )
}

const Navbar: React.FC<{ onHeaderClick: () => void, onCartClick: () => void, state: PageState | null, onMoreClick?: () => void, cartLength: number }> = ({ onHeaderClick, onCartClick, state, onMoreClick, cartLength }) => {
  return (
    <nav className="h-full mx-4 flex items-center justify-between text-black">
      <h1 className="text-2xl font-semibold" onClick={onHeaderClick}>Brunch</h1>
      <ul className="flex gap-8 min-w-20 justify-between">
        <li><button className='relative text-xl' onClick={onCartClick}><FontAwesomeIcon icon={faCartShopping} />{cartLength > 0 && <label className='absolute flex items-center justify-center h-5 w-5 text-xs text-white rounded-full top-[-10px] right-[-10px] bg-red-500'>{cartLength}</label>}</button></li>
        <li><button className='text-xl' onClick={onMoreClick}>{state && state.state === 'more' ? <FontAwesomeIcon icon={faClose} /> : <FontAwesomeIcon icon={faBarsStaggered} />} </button></li>
      </ul>
    </nav>
  )
}
type Loading = { state: 'loading' }
type FetchMenuFailedState = { state: 'failed_to_fetch_menu', errorMessage: string }
type FetchMenuSuccessState = { state: 'fetched_menu', menus: Menu[] }
type MenuState = Loading | FetchMenuFailedState | FetchMenuSuccessState

const Menus: React.FC<{ onAddClick: (menu: Menu) => void }> = ({ onAddClick }) => {
  const [state, setState] = useState<MenuState | null>(null);
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      getMenu();
      isMountedRef.current = true;
    }
  }, []);

  const getMenu = async () => {
    setState({ state: 'loading' });
    try {
      const newMenus = await fetchMenus();
      setState({ state: 'fetched_menu', menus: newMenus })
    } catch (error) {
      setState({ state: 'failed_to_fetch_menu', errorMessage: 'Error occur while fetching menus from the server!' });
    }
  }
  return (
    <div className={`relative ${state && (state.state === 'loading' || state.state === 'failed_to_fetch_menu') && 'h-full'}`}>      {state?.state === 'failed_to_fetch_menu' && <label className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-medium">{state.errorMessage}</label>}
      {state?.state === 'loading' && <label className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-medium">Loading...</label>}
      <div className="grid gap-3 grid-cols-[repeat(auto-fit,minmax(170px,1fr))]">
        {
          state?.state === "fetched_menu" && state.menus.map((menu, index) => (
            <div className={`relative flex flex-col rounded-md bg-gray-10 ${!menu.isAvailable ? 'opacity-[0.7]' : ''}`} key={index}>
              {
                !menu.isAvailable &&
                <label className="absolute top-0 left-0 text-xs p-1 bg-red-600 rounded-[6px_0px_10px_0px] text-white font-medium">unavailable</label>
              }
              <img className="min-h-[150px] max-h-[150px] object-cover rounded-t-md" src={menu.img} alt="" />
              <div className="h-full rounded-b-md box-border p-2 flex flex-col gap-2 justify-end bg-gradient-to-t from-gray-300 from-80%  to-emerald-0 to-100% mt-[-30px]">
                <div className='mt-[10px] flex flex-col'>
                  <label className="text-slate-800 font-medium">{menu.name}</label>
                  <label className="text-slate-800 font-medium">₹{menu.price}</label>
                </div>
                <button className="self-center text-sm px-3 py-1 font-medium rounded-full text-slate-800 flex items-center gap-1 border-[2px] border-slate-800" disabled={!menu.isAvailable} onClick={() => onAddClick(menu)}><FontAwesomeIcon icon={faCartShopping} />Add</button>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const Cart: React.FC<{ user: any, carts: Menu[], onRemove: (index: number) => void, onQntyUpdated: (type: string, index: number) => void, onLoginOption: () => void }> = ({ user, carts, onRemove, onQntyUpdated, onLoginOption }) => {
  const navigate = useNavigate();
  const gotoOrder = () => {
    if (carts.length > 0) {
      if (!user) {
        onLoginOption();
      } else {
        const items = carts.map(i => ({ id: i.id, name: i.name, price: i.price, quantity: i.quantity }));
        const orderItems = encodeURIComponent(JSON.stringify(items));
        navigate(`/order?data=${orderItems}`);
      }
    }
  }
  return (
    <div className="h-full w-full relative">
      {carts.length === 0 && <label className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-medium">Empty cart!</label>}
      <div className="h-full box-border flex flex-col gap-2">
        <h2 className="m-0 text-xl font-medium">Cart</h2>
        <div className="flex-1 box-border pr-4 flex flex-col overflow-auto">
          {carts.map((cart, index) => (
            <div className="py-3 border-b flex gap-2 justify-between" key={index}>
              <div className="flex gap-2">
                <img className="h-[70px] w-[70px] rounded object-cover" src={cart.img} alt="" />
                <div className="flex flex-col">
                  <label className="font-medium">{cart.name}</label>
                  <label className="text-sm font-medium">₹{cart.price}</label>
                  <div className="flex items-center gap-4">
                    <label className="text-sm font-medium">Quantity:</label>
                    <div className="w-24 flex items-center justify-between border rounded">
                      <button className="text-xs px-3 py-1" onClick={() => onQntyUpdated('minus', index)}><FontAwesomeIcon icon={faMinus} /></button>
                      <label className="text-md">{cart.quantity}</label>
                      <button className="text-xs px-3 py-1" onClick={() => onQntyUpdated('plus', index)}><FontAwesomeIcon icon={faPlus} /></button>
                    </div>
                  </div>
                </div>
              </div>
              <button className="h-[30px] p-0 text-sm text-red-500 flex gap-2 items-center font-medium" onClick={() => onRemove(index)}><FontAwesomeIcon icon={faTrash} />Remove</button>
            </div>
          ))}
        </div>
        <button className="h-[40px] bg-green-700 text-white rounded font-medium" onClick={gotoOrder}>Continue  ₹{carts.reduce((i, c) => i + (c.price * c.quantity), 0)}</button>
      </div>
    </div>
  )
}

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const footerItems = [
    { url: '/terms&policies/termsofuse', name: 'Terms of Use' },
    { url: '/terms&policies/privacypolicy', name: 'Privacy Policy' },
    { url: '/terms&policies/refundpolicy', name: 'Refund Policy' },
    { url: '/terms&policies/logisticspolicy', name: 'Logistics Policy' },
  ]

  const openClick = (url: string) => {
    window.open(url, '_blank');
  }
  return (
    <div className='h-full w-full flex items-center justify-center gap-3'>
      <p className="text-black text-sm font-medium">&copy; 2024 Royal Veg. All rights reserved.</p>
      <ul className='flex'>
        {
          footerItems.map((item, index) => (
            <li key={index}>
              <a className={`text-sm font-medium px-2 cursor-pointer ${index !== footerItems.length - 1 ? 'border-r border-slate-500' : ''}`} key={index} onClick={() => navigate(item.url)}>{item.name}</a>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

const MoreDialog: React.FC<{ user: any, onLogout: () => void, onLoginClick: () => void }> = ({ user, onLogout, onLoginClick }) => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('user');
    onLogout();
  }

  const openClick = (url: string) => {
    window.open(url, '_blank');
  }

  return (
    <div className='flex h-full flex-col gap-4 justify-between overflow-auto'>
      <div className="flex flex-col">
        {
          user ?
            <div className='flex flex-col'>
              <h3 className='border-b py-2 text-xl font-medium'>{user?.fullname}</h3>
              {user.phone &&
                <label className='border-b py-2 flex items-center gap-4 font-medium' ><FontAwesomeIcon className='min-w-6' icon={faPhone} /><label className='flex-1 text-start'>{user?.phone}</label></label>
              }
              <button className='border-b py-2 flex items-center gap-4 font-medium' onClick={() => navigate('/orders')}><FontAwesomeIcon className='min-w-6' icon={faBox} />Orders</button>
              {/* <button className='border-b py-2 flex items-center gap-4 font-medium' onClick={() => navigate('/addresses')}><FontAwesomeIcon className='min-w-6' icon={faLocationDot} />Addresses</button> */}
              <button className='border-b py-2 flex items-center gap-4 font-medium text-red-500' onClick={logout}><FontAwesomeIcon className='min-w-6' icon={faRightToBracket} style={{ transform: 'rotate(180deg)' }} />Logout</button>
            </div>
            :
            <button className="self-center w-3/5 text-white bg-black p-2 rounded-full font-medium" onClick={onLoginClick}>Login/Signup</button>
        }
        <div className='flex flex-col lg:hidden'>
          <h3 className='border-b mt-6 py-2 text-xl'>Others</h3>
          <button className='border-b py-2 flex items-center gap-4 font-medium' onClick={() => navigate('/terms&policies/termsofuse')}><FontAwesomeIcon className='min-w-6' icon={faFile} />Terms of Use</button>
          <button className='border-b py-2 flex items-center gap-4 font-medium' onClick={() => navigate('/terms&policies/privacypolicy')}><FontAwesomeIcon className='min-w-6' icon={faShieldHalved} />Privacy Policy</button>
          <button className='border-b py-2 flex items-center gap-4 font-medium' onClick={() => navigate('/terms&policies/refundpolicy')}><FontAwesomeIcon className='min-w-6' icon={faShieldHalved} />Refund Policy</button>
          <button className='border-b py-2 flex items-center gap-4 font-medium' onClick={() => navigate('/terms&policies/logisticspolicy')}><FontAwesomeIcon className='min-w-6' icon={faTruckFast} />Logistics Policy</button>
        </div>
      </div>
      <p className="text-black text-sm font-medium lg:hidden">&copy; 2024 Royal Veg. All rights reserved.</p>
    </div>
  )
}

const LoginDialog: React.FC<{ onCloseClick: () => void, onGuestCreateCallback: (state: PageState) => void }> = ({ onCloseClick, onGuestCreateCallback }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const createGuestUser = async () => {
    setLoading(true);
    try {
      const guestRes = await createGuest();
      if (guestRes.state === 'guest_created') {
        onGuestCreateCallback(guestRes)
      }
    } catch (error: any) {
      let errorMessage = 'Internal Server Error';
      if (error.code === 'ERR_NETWORK') {
        errorMessage = error.message;
      } else if (error.code === 'ERR_BAD_REQUEST') {
        errorMessage = error.response?.data?.error || 'Bad Request Error';
      }
      console.log(errorMessage);
      onGuestCreateCallback({ state: 'failed_to_create_guest', errorMessage });
    }
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-[#00000094]">
      <div className="bg-white max-w-[300px] w-[90%] p-3 flex flex-col rounded gap-3">
        <button className="text-sm bg-black text-white h-10 rounded font-medium" disabled={loading} onClick={() => navigate('/login')}>Login/Register</button>
        <button className="text-sm bg-black text-white h-10 rounded font-medium flex items-center justify-center" disabled={loading} onClick={createGuestUser}>{loading ? <ReactLoading type={'balls'} color={'white'} height={30} width={30} /> : 'Continue as Guest'}</button>
        <button className="font-medium border p-2 text-sm" onClick={onCloseClick} disabled={loading}>Close</button>
      </div>
    </div>
  )
}

const GuestCreateALert: React.FC<{ message: string, onGuestAlertDialogClose: () => void }> = ({ message, onGuestAlertDialogClose }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-[#00000094]">
      <div className="bg-white max-w-[300px] w-[90%] p-3 flex flex-col rounded gap-3">
        <label>{message}</label>
        <button className="font-medium border p-2 text-sm" onClick={onGuestAlertDialogClose}>Close</button>
      </div>
    </div>
  )
}

function doVibrate() {
  if (navigator.vibrate) {
    navigator.vibrate(40);
  } else {
    console.log('Vibration not supported');
  }
}

export default Home